* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
  background-color: #dcd3ea;
}

.header {
  margin: 1rem auto;
  text-align: center;
  letter-spacing: 1px;
}

.container {
  display: flex;
}

.side {
  flex: 0 0 200px; /* Fixed size of 200px */
  width: 200px;
  padding: 10px;
  background-color: #e0e0e0;
}

.content {
  flex: 2; /* Takes remaining space */
  padding: 10px;
}


.errorMsg,
.loading {
  text-align: center;
  font-size: 1.1rem;
  letter-spacing: 1px;
}

.errorMsg {
  color: #d86161;
}

.form-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background overlay */
  z-index: 9999; /* Adjust the z-index as needed to ensure it appears above other elements */
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-popup {
  background-color: #fff;
  padding: 20px;
}

.form-popup input[type="text"],
.form-popup input[type="email"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-popup button[type="submit"] {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #039be5;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-popup button[type="submit"]:hover {
  background-color: #0277bd;
}

.load-more {
  margin: 1rem 0;
  text-align: center;
}

.btn-grad {
  font-size: 1rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  background-image: linear-gradient(
    to right,
    #77a1d3 0%,
    #79cbca 51%,
    #77a1d3 100%
  );
  margin: 10px;
  padding: 10px 30px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  cursor: pointer;
  border-radius: 5px;
}

@media screen and (max-width: 400px) {
  .random-user {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}